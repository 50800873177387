<template lang='pug'>

    span(
        :class='$style.container'
        tabindex='0'
    )

        span(
            :class='$style.current'
            role='button'
            @click='active = !active'
        )

            h2 {{ locales[$route.meta.locale].short  }}

            svg(
                viewBox='0 0 23 43'
                v-show='!active'
            )

                polyline(points='21.5 1.5 1.5 21.5 21.5 41.5')

        span(
            :class='$style.menu'
            v-show='active'
        )

            router-link(
                v-for='locale in locales'
                :to='locale.path'
                :key='locale.path'
            ) {{ locale.short }}

</template>

<script>

export default {
    name: 'langTool',
    props: ['locales'],
    data ()
    {
        return {
            active: false
        }
    },
    watch:
    {
        '$route' ()
        {
            this.active = false
        }
    }
}

</script>

<style module lang="scss">

.container {
    position: relative;
    margin-right: 1rem;
    cursor: pointer;
    @media screen and (min-width: 64rem)
    {
        margin-right: 3rem;
    }
}

.current {
    position: relative;
    display: block;
    padding-right: 1.2rem;

    h2 {
        @include fBold;
        @include fUpper;
        font-size: 0.9rem;
        @media screen and (min-width: 25rem)
        {
            font-size: 1rem;
        }
    }

    svg {
        display: block;
        position: absolute;
        top: 45%;
        right: 0.2rem;
        height: 0.8rem;
        width: 0.4rem;
        transform: translateY(-50%) rotate(-90deg);
        fill: none;
        stroke: $purple;
        stroke-width: 5px;
    }
}

.menu {
    position: absolute;
    bottom: 0;
    right: 0;
    transform: translateY(calc(100%));
    @include bgPurple;
    padding: 0.5rem 1.2rem;

    a {
        @include fBold;
        @include fUpper;
        @include fgWhite;
        margin: 0 0.5rem;
    }
}

</style>
