<template lang="pug">

section(:class='$style.container')

    div(
        :class='$style.employe'
        v-for='employe in employes'
        v-lazy='baseUrl + "/" + employe.value.portrait.path'
    )

        div(
            :class='$style.employeOverlay'
            tabindex='0'
        )

            h2 {{ employe.value.name }}

            span {{ employe.value.profession }}

            span

                a(':href'='"mailto:" + employe.value.email') {{ employe.value.email }}

</template>

<script>

export default {
    name: 'employes',
    props: ['baseUrl', 'employes']
}
</script>

<style module lang='scss'>

.container {
    display: grid;
    grid-template-columns: 1;
    @media screen and (min-width: 48rem) {
        grid-template-columns: repeat(2, 1fr);
    }
    @media screen and (min-width: 64rem) {
        grid-template-columns: repeat(3, 1fr);
    }
}

.employe {
    width: 100%;
    height: 50vh;
    min-height: 16rem;
    background-size: cover;
    background-position: center;
    position: relative;
    @media screen and (min-width: 64rem) {
        height: 33.33vh;
    }
}

.employeOverlay {
    @include fMiddle;
    position: absolute;
    top: 1.2rem;
    bottom: 1.2rem;
    left: 1.2rem;
    right: 1.2rem;
    background-color: rgba(255,255,255,0.9);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    opacity: 0;
    transition: opacity 0.5s linear;

    &:hover {
        opacity: 1;

        h2 {
            @include fBold;
            display: block;
        }

        span:last-of-type {
           display: block;
        }
    }

    h2 {
        @include fBold;
        display: none;
    }

    span:last-of-type {
        margin-top: 1rem;
        display: none;
    }
}

</style>
