<template lang="pug">

    section(:class='$style.container')

        div(:class='$style.semifluid')

            //- header

            //-     h2(:class='$style.headline') {{ headline }}

            div(:class='$style.partners')

                div(
                    v-for='partner in partners'
                    :class='$style.partner'
                )

                    div

                        p {{ partner.value.description }}

                        span

                            a(
                                :href='partner.value.url'
                                target='_blank'
                            ) {{ partner.value.title }}

                    a(
                        :href='partner.value.url'
                        target='_blank'
                    )

                        img(:src='baseUrl + "/" + partner.value.logo.path')


</template>

<script>

export default {
    name: 'partners',
    props: ['headline', 'partners', 'baseUrl']

}

</script>

<style module lang="scss">

.container {
    @include padV;
}

.semifluid {
    @include semifluid;
}

.headline {
    @include fTitle2;
    @include fCenter;
    margin-bottom: 0.8rem;
}

.partners {
    display: grid;
    justify-content: center;
    flex-wrap: wrap;
    gap: 4rem;

    @media screen and (min-width: 768px) {
        grid-template-columns: repeat(2, 1fr);
    }

}

.partner {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    @include fMiddle;
    p {
        @include fCenter;
    }

    span {
        @include fCenter;
        display: block;
        margin-bottom: 2rem;
    }

    a {

        @include fgPurple;

    }

    img {
        width: 100%;
        max-width: 10rem;
        height: 4rem;
        object-fit: contain;
        object-position: center;
    }
}

</style>
