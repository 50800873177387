<template lang="pug">

  section(:class='$style.container')

</template>

<script>

import google from 'google'

export default {
    name: 'gmaps',
    props: ['placeId'],
    data ()
    {
        return {
            map: null,
            marker: null
        }
    },
    mounted ()
    {
        this.map = new google.maps.Map(this.$el, {
            disableDefaultUI: true,
            draggable: false,
            styles:
            [
                {
                    featureType: 'all',
                    stylers:
                    [
                        {
                            saturation: -100
                        },
                        {
                            lightness: 10
                        }
                    ]
                },
                {
                    featureType: 'poi',
                    stylers:
                    [
                        {
                            visibility: 'off'
                        }
                    ]
                }
            ]
        })

        this.bounds = new google.maps.LatLngBounds()

        this.marker = new google.maps.Marker({
            position: { lat: 47.4093176, lng: 9.2824881 },
            map: this.map,
            icon: '/static/ui/marker.png'
        })

        this.marker.addListener('click', () =>
        {
            window.open('https://www.google.com/maps/place/Industriestrasse+149,+9200+Gossau/@47.4093176,9.2824881,16z/data=!3m1!4b1!4m6!3m5!1s0x479ae0f4f3838ae3:0x8aec8b11f0a380ca!8m2!3d47.4093176!4d9.2850684!16s%2Fg%2F11c2kn39bw?entry=ttu&g_ep=EgoyMDI0MDgyNy4wIKXMDSoASAFQAw%3D%3D', '_blank')
        })

        this.bounds.extend(this.marker.getPosition())

        this.marker2 = new google.maps.Marker({
            position: { lat: 47.4250889, lng: 9.3709648 },
            map: this.map,
            icon: '/static/ui/marker.png'
        })

        this.marker2.addListener('click', () =>
        {
            window.open('https://www.google.com/maps/place/Poststrasse+6,+9000+St.+Gallen/@47.4250889,9.3709648,17z/data=!3m1!4b1!4m6!3m5!1s0x479b1e495d8e9b09:0xb4ac497d5d8bba91!8m2!3d47.4250889!4d9.3735451!16s%2Fg%2F11c2ft7nsg?entry=ttu&g_ep=EgoyMDI0MDgyNy4wIKXMDSoASAFQAw%3D%3D', '_blank')
        })

        this.bounds.extend(this.marker2.getPosition())

        this.map.fitBounds(this.bounds)

        window.addEventListener('resize', this.resize)
    },
    unmounted ()
    {
        window.removeEventListener('resize', this.resize)
    },
    methods:
    {
        resize ()
        {
            this.map.fitBounds(this.bounds)
        }
    }
}
</script>

<style module lang='scss'>

.container {
    width: 100%;
    height: 50vh;
}

</style>
