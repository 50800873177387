<template lang="pug">

    div(:class='$style.container')

        imageSlider(
            :images='images'
            :interval='5000'
            :baseUrl='baseUrl'
        )

        div(:class='$style.headline')

            monogram(:class='$style.monogram')

            h2 {{ claim }}

</template>

<script>

import imageSlider from '@/components/imageSlider'

import monogram from '@/assets/ui/monogram.svg?inline'

export default {
    name: 'splash',
    props: ['images', 'interval', 'baseUrl', 'claim'],
    components:
    {
        imageSlider,
        monogram
    }
}

</script>

<style module lang="scss">

@import '@/styles/shared/monogram.module.scss';

.container {
    position: relative;
    height: 80vh;
}

.headline {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    img {
        margin: 0 auto;
        padding-bottom: 4vw;
    }

    h2 {
        @include fBold;
        @include fgWhite;
        font-size: 8vw;
    }
}

</style>
