<template lang="pug">

    div(:class='$style.container')

        monogram(:class='$style.monogram')

</template>

<script>

import monogram from '@/assets/ui/monogram.svg?inline'

export default {
    name: 'curtain',
    components:
    {
        monogram
    }
}
</script>

<style module lang="scss">

@import '@/styles/shared/monogram.module.scss';

.container {
    @include bgPurple;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 9999;
    transform: translateY(0%);

    .monogram {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}

</style>
