<template lang="pug">

    section(:class='$style.container')

        div(:class='$style.semifluid')

            p {{ synopsis }}

            monogram(:class='$style.monogram')

</template>

<script>

import monogram from '@/assets/ui/monogram.svg?inline'

export default {
    name: 'synopis',
    props: ['synopsis'],
    components:
    {
        monogram
    }
}

</script>

<style module lang="scss">

@import '@/styles/shared/monogram.module.scss';

.container {
    @include bgPurple;
    @include fgWhite;
    @include padV;
    @include fCenter;
}

.semifluid {
    @include semifluid;
    display: flex;
    flex-direction: column;
    align-items: center;

    p {
        @include fBold;
        font-size: 1.2rem;
        @media screen and (min-width: 32rem) {
            font-size: 1.8rem;
        }
        @media screen and (min-width: 48rem) {
            font-size: 2.4rem;
        }
        @media screen and (min-width: 64rem) {
            font-size: 3rem;
        }
        margin-bottom: 2rem;
    }
}

</style>
