<template lang="pug">

  div(:class='$style.container')

    img(
        v-lazy='baseUrl + images[index].path'
        :key='images[index].path'
    )

</template>

<script>

export default {
    name: 'imageSlider',
    props: ['baseUrl', 'images', 'interval'],
    data ()
    {
        return {
            index: 0,
            intervalRef: null
        }
    },
    mounted ()
    {
        this.intervalRef = setInterval(() =>
        {
            this.next()
        }, this.interval)
    },
    beforeDestroy ()
    {
        clearInterval(this.intervalRef)
    },
    methods:
    {
        next ()
        {
            this.index = (this.index + 1 === this.images.length) ? 0 : this.index + 1
        }
    }
}
</script>

<style module lang='scss'>

.container {
    width: 100%;
    height: 100%;
    background-color: #222;

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
    }
}

</style>
