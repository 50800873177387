/* eslint-disable brace-style */

import Vue from 'vue'

const CHANGE = 'change'
const EL = 'el'

const scrollSpyPlugin = () => {}

export default scrollSpyPlugin
export const scrollSpyPluginEvents = new Vue({})

scrollSpyPlugin.install = function (Vue)
{
    Vue.directive('scrollSpy', {
        inserted: (el, binding) =>
        {
            el.offset = binding.value.offset

            const scrollHandler = () =>
            {
                var current = null
                const children = el.children

                for (var i = 0; i < children.length; i++)
                {
                    if (children[i].getBoundingClientRect().top - el.offset < 0 && children[i].getBoundingClientRect().bottom > el.offset)
                    {
                        current = i
                        break
                    }
                }
                scrollSpyPluginEvents.$emit(CHANGE, current)
            }

            window.addEventListener('scroll', scrollHandler, false)
            scrollSpyPluginEvents.$emit(EL, { offset: el.offset, target: el })
        },
        update: (el, binding) =>
        {
            el.offset = binding.value.offset
            scrollSpyPluginEvents.$emit(EL, { offset: el.offset, target: el })
        }
    })

    Vue.directive('scrollSpyNav', {
        inserted: (el) =>
        {
            const scrollTo = () =>
            {
                const child = el.target.childNodes[el.index]
                const rect = child.getBoundingClientRect()

                window.scrollBy({
                    top: rect.top - el.offset + 1,
                    behavior: 'smooth'
                })
            }

            scrollSpyPluginEvents.$on(CHANGE, index =>
            {
                if (index === el.index)
                {
                    el.classList.add('active')
                }
                else
                {
                    el.classList.remove('active')
                }
            })

            scrollSpyPluginEvents.$on(EL, ref =>
            {
                el.offset = ref.offset
                el.target = ref.target
            })

            el.addEventListener('click', scrollTo)
        },
        update: (el, binding) =>
        {
            el.index = binding.value.index
        }
    })
}
