<template lang="pug">

  div(id='app')

    transition(
        @leave='slideUp'
        ':css'='false'
    )

        curtain( v-if='!apiData')

    template(v-if='apiData')

        header(
            :class='$style.header'
            ref='header'
        )

            h1(
                class='title'
                @click='scrollTo(0)'
            )

                span Muzzarini

                | &nbsp;Treuhand & Beratung

            div(:class='$style.headerRight')

                langTool(:locales='locales')

                nav(:class='$style.headerRightNav')

                    h2(
                        @click='menu = false'
                        v-scroll-spy-nav='{index: index}'
                        v-for='(item, index) in getNavigation'
                        role='button'
                    ) {{ item }}

                nav(
                    :class='$style.headerRightNavMobile'
                    v-show='menu'
                )

                    h2(
                        @click='menu = false'
                        v-scroll-spy-nav='{index: index}'
                        v-for='(item, index) in getNavigation'
                        role='button'
                    ) {{ item }}

                span(
                    :class='[$style.headerRightBurger, {[$style.headerRightBurgerActive]: menu}]'
                    @click='menu = !menu'
                    role='button'
                )

                    span

                    span

                    span

                    span

        main(class='main')

            splash(
                :images='apiData.slideshow'
                :interval='5000'
                :baseUrl='baseUrl'
                :claim='setLocalizedField("claim")'
            )

            synopsis(:synopsis='setLocalizedField("synopsis")')

            div(v-scroll-spy='{offset: Math.abs(calcs.hh)}')

                competencesSlider(
                    :competences='setLocalizedField("competences")'
                    :locales='locales'
                )

                bexio(
                    :lead='setLocalizedField("bexioLead")'
                    :headline='setLocalizedField("bexioHeadline")'
                    :copy='setLocalizedField("bexioCopy")'
                )

                cryptos(
                    :title='setLocalizedField("cryptoTitle")'
                    :lead='setLocalizedField("cryptoLead")'
                    :bubbles='setLocalizedField("cryptoBubbles")'
                )

                employes(
                    :employes='apiData.employes'
                    :baseUrl='baseUrl'
                )

                //- jobs(
                //-     v-if='apiData.jobs[0]'
                //- )

                div

                    gmaps(':placeId'='"ChIJywWC8_TgmkcRZ7aeKsTBZBU"')

                    partners(
                        :headline='setLocalizedField("partnerHeadline")'
                        :partners='setLocalizedField("partner")'
                        :baseUrl='baseUrl'
                    )

            privacyPolicy(
              :showPrivacyPolicy='gdprSettings.privacyPolicy'
              :privacyPolicy='setLocalizedField("privacyPolicy")'
              ref='privacyPolicy'
            )

            impressum(
                :showImpressum='gdprSettings.impressum'
                :impressum='setLocalizedField("impressum")'
                ref='impressum'
            )

            footerArea(
                :locales='locales'
                :footer='setLocalizedField("footer")'
                :gdpr='locales[$route.meta.locale].gdrp'
            )

              li(v-if='setLocalizedField("impressumLinkTitle")')

                button(
                  showImpressum='showImpressum ?? true'
                  @click='showHideSupplementary("impressum")'
                ) {{ setLocalizedField("impressumLinkTitle") }}

              li(v-if='setLocalizedField("privacyPolicyLinkTitle")')

                button(
                  showPrivacyPolicy='showPrivacyPolicy ?? true'
                  @click='showHideSupplementary("privacyPolicy")'
                ) {{ setLocalizedField("privacyPolicyLinkTitle") }}

</template>

<script>

import anime from 'animejs'
import axios from 'axios'

import bexio from '@/components/bexio'
import competencesSlider from '@/components/competencesSlider'
import cryptos from '@/components/cryptos'
import curtain from '@/components/curtain'
import employes from '@/components/employes'
import footerArea from '@/components/footerArea'
import gmaps from '@/components/gmaps'
import jobs from '@/components/jobs'
import langTool from '@/components/langTool'
import partners from '@/components/partners'
import synopsis from '@/components/synopsis'
import splash from '@/components/splash'
import privacyPolicy from '@/components/privacyPolicy'
import impressum from '@/components/impressum'

export default {
    name: 'App',
    data ()
    {
        return {
            baseUrl: 'https://muzzarini.wearetkf.ch',
            filePath: '/storage/uploads',
            apiToken: 'db30e7e22e5eeb86c9191fbf9cd909',
            apiPath: '/api/collections/get/Website',
            apiData: null,
            apiError: null,
            calcs:
            {
                ww: 0,
                wh: 0,
                hh: 0
            },
            menu: false,
            nav:
            {
                german: ['Leistungen', 'bexio', 'Crypto Assets', 'Team', 'Offene Stelle', 'Kontakt'],
                english: ['Services', 'bexio', 'Crypto Assets', 'Team', 'Jobs', 'Contact'],
                italian: ['Servizi', 'bexio', 'Crypto Assets', 'Team', 'Posizione Aperta', 'Contatto']
            },
            locales:
            {
                german: {
                    path: '/',
                    short: 'de',
                    gdrp: 'Diese Website ist konform mit der neuen Datenschutz-Grundverordnung. Es erfolgt keine Verarbeitung von personenbezogenen Daten.',
                    services: 'Leistungen'
                },
                english: {
                    path: '/en',
                    short: 'en',
                    gdrp: 'This website complies with the new data protection regulation. Personal data will not be processed.',
                    services: 'Services'
                },
                italian: {
                    path: '/it',
                    short: 'it',
                    gdrp: 'Questo sito web è conforme alla nuova normativa sulla protezione dei dati personali. I dati personali non saranno trattati.',
                    services: 'Servizi'
                }
            },
            gdprSettings: {
                impressum: false,
                privacyPolicy: false
            }
        }
    },
    created ()
    {
        axios.get(this.baseUrl + this.apiPath + '?token=' + this.apiToken).then(res =>
        {
            this.apiData = res.data.entries[0]
        }).catch(err =>
        {
            this.apiError = err
        })
    },
    beforeDestroy ()
    {
        window.removeEventListener('resize', this.resize)
    },
    computed:
    {
        getNavigation ()
        {
            const nav = this.nav[this.$route.meta.locale].slice(0)

            if (!this.apiData.jobs[0])
            {
                nav.splice(4, 1)
            }

            return nav
        }
    },
    methods:
    {
        resize ()
        {
            this.$nextTick(() =>
            {
                this.calcs.ww = window.innerWidth
                this.calcs.wh = window.innerHeight
                this.calcs.hh = this.$refs.header.offsetHeight
            })
        },
        scrollTo (top)
        {
            window.scrollTo({
                top: top,
                behavior: 'smooth'
            })
        },
        slideUp (el, done)
        {
            anime({
                targets: el,
                translateY: '-100%',
                easing: 'linear',
                duration: 300,
                delay: 2000,
                complete: done
            })
        },
        setLocalizedField (field)
        {
            const locale = (this.$route.meta.locale !== 'german') ? '_' + this.$route.meta.locale : ''

            return this.apiData[field + locale]
        },
        setMeta ()
        {
            document.getElementsByName('description')[0].setAttribute('content', this.setLocalizedField('meta'))
        },
        showHideSupplementary (prop)
        {
            if (prop === 'impressum')
            {
                this.gdprSettings.privacyPolicy = false
                this.gdprSettings.impressum = !this.gdprSettings.impressum
            }
            else if (prop === 'privacyPolicy')
            {
                this.gdprSettings.impressum = false
                this.gdprSettings.privacyPolicy = !this.gdprSettings.privacyPolicy
            }
        }
    },
    components:
    {
        bexio,
        competencesSlider,
        cryptos,
        curtain,
        employes,
        footerArea,
        gmaps,
        jobs,
        langTool,
        partners,
        synopsis,
        splash,
        privacyPolicy,
        impressum
    },
    watch:
    {
        apiData ()
        {
            this.$nextTick(() =>
            {
                setTimeout(() =>
                {
                    this.resize()
                }, 10)
                window.addEventListener('resize', this.resize)
            })

            this.setMeta()
        },
        $route ()
        {
            this.setMeta()
        }
    }
}
</script>

<style lang="scss">

@import '@/styles/globals/main.scss';

</style>

<style module lang='scss'>

.header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    border-top: 0.2rem solid $purple;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1.2rem;
    box-sizing: border-box;
    z-index: 9998;
    background-color:$white;
    @media screen and (min-width: 32rem)
    {
        padding: 1.2rem 3rem;
    }
    @media screen and (min-width: 64rem)
    {
        align-items: baseline;
    }

    h1 {
        @include fBold;
        cursor: pointer;
        font-size: 0.9rem;
        @media screen and (min-width: 25rem)
        {
            font-size: 1rem;
        }
        @media screen and (min-width: 32rem)
        {
            font-size: 1.4rem;
        }

        span {
            text-decoration: underline;

        }
    }
}

.headerRight {
    display: flex;
    align-items: bottom;
}

.headerRightNav {
    display: none;
    @media screen and (min-width: 64rem) {
        display: flex;
    }

    h2 {
        @include fBold;
        margin-right: 2rem;

        &:last-of-type {
            margin-right: 0;
        }

        &:global(.active) {
            @include fgPurple;
        }
    }
}

.headerRightNavMobile {
    @include bgPurple;
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    @media screen and (min-width: 64rem) {
        display: none;
    }

    h2 {
        @include fgWhite;
        @include fBold;
        font-size: 8vmin;
        margin: 2vmin;

        &:global(.active) {
            text-decoration: underline;
        }
    }
}

.headerRightBurger {
    position: relative;
    z-index: 1;
    width: 26px;
    height: 16px;
    display: block;
    @media screen and (min-width: 64rem) {
        display: none;
    }

    span {
        position: absolute;
        @include bgBlack;
        display: block;
        height: 2px;
        width: 100%;
        transition: transform 0.3s;

        &:nth-child(1) {
            top: 0;
        }

        &:nth-child(2),
        &:nth-child(3) {
            top: 7px;
        }

        &:nth-child(4) {
            top: 14px;
        }
    }
}

.headerRightBurgerActive {
    span {
        position: absolute;
        @include bgWhite;
        display: block;
        height: 3px;
        width: 100%;

        &:nth-child(1),
        &:nth-child(4) {
            top: 0;
            transform: scaleX(0);
        }

        &:nth-child(2) {
            transform: rotate(45deg);
        }

        &:nth-child(3) {
            transform: rotate(-45deg);
        }
    }
}

</style>
