<template lang="pug">

    section(:class='$style.container')

        div(:class='$style.semifluid')

            header

                h2(:class='$style.headline') {{ headline }}

            p(:class='$style.lead') {{ lead }}

            div(:class='$style.flex')

                p(:class='$style.flexCopy') {{ copy }}

                img(
                    v-lazy='require("@/assets/ui/bexio.png")'
                    :class='$style.flexLogo'
                )

</template>

<script>

export default {
    name: 'bexio',
    props: ['headline', 'lead', 'copy']

}

</script>

<style module lang='scss'>

.container {
    @include padV;
    @include bgGrey;
}

.semifluid {
    @include semifluid;
}

.headline {
    @include fTitle2;
    @include fCenter;
    margin-bottom: 0.8rem;
}

.lead {
    @include fBig;
    @include fCenter;
    margin-bottom: 3rem;
}

.flex {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: flex-start;
    @media screen and (min-width: 64rem) {
        justify-content: space-between;
    }
}

.flexCopy {
    @include fMiddle;
    white-space: pre-wrap;
    margin-bottom: 1rem;
    width: 100%;
    @media screen and (min-width: 64rem) {
        width: calc(100% - 18rem);
    }
}

.flexLogo {
    width: 12rem;
    height: auto;
    @media screen and (min-width: 64rem) {
        width: 15rem;
    }
}

</style>
