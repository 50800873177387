<template lang="pug">

    section(:class='$style.container')

        header

            h2 {{ apiData.jobs[0].value.title }}

        p {{ apiData.jobs[0].value.description }}

        a(
            ':href'='baseUrl + filePath + apiData.jobs[0].value.joboffer.path'
            target='_blank'
        ) Stellenbeschreibung

</template>

<script>

export default {
    name: 'jobs'
}

</script>

<style module lang="scss">

.container {
    @include bgPurple;
    @include fgWhite;
    @include fCenter;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 1.5rem;
    padding: 1.2rem;
    box-sizing: border-box;
    height: 50vh;
    @media screen and (min-width: 32rem)
    {
        padding: 3rem;
    }

    h2 {
        font-size: 5vmin;
    }

    a {
        margin-top: 3rem;
        font-weight: bold;
    }

    .monogram {
        display: inline-block;
        margin-bottom: 4rem;
    }
}

</style>
