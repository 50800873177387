<template lang="pug">

    footer(:class='$style.container')

        div(:class='$style.flex')

            div(:class='$style.address')

                div(v-html='footer[0].value')

                div(v-html='footer[1].value')

                div(v-html='footer[2].value')

            a(
                href='https://www.treuhandsuisse.ch/'
                target='_blank'
            )

                img(
                    v-lazy='require("@/assets/ui/ths_" + locales[$route.meta.locale].short + ".png")'
                    :class='$style.ths'
                )

        div(:class='$style.flex')

          ul(:class='$style.gdprMenu')

            slot

        span(:class='$style.gdpr') {{ gdpr }}

</template>

<script>

export default {
    name: 'footerArea',
    props: ['footer', 'gdpr', 'locales']
}

</script>


<style module lang="scss">

.container {
  @include bgPurple;
  @include fgWhite;
}

.flex {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 3rem 1.2rem 4rem 1.2rem;
  @media screen and (min-width: 32rem){
    padding: 3rem 3rem 3rem 3rem;
  }

  &:nth-child(1) {
    padding-bottom: 0;
    @media screen and (min-width: 32rem){
      padding-bottom: 0;
    }
  }
  &:nth-child(2) {
    padding-top: 0;
    @media screen and (min-width: 32rem){
      padding-top: 0;
    }
  }
}

.address {
  display: flex;
  flex-wrap: wrap;

  strong {
    @include fBold;
  }

  p {
    margin-right: 4rem;
    margin-bottom: 2rem;
  }
}

.ths {
  width: 20rem;
  height: auto;
  margin-bottom: 2rem;
}

.gdpr {
  @include fCenter;
  background: rgba(0,0,0,0.5);
  display: block;
  padding: 1rem 1.2rem;
  box-sizing: border-box;
  @media screen and (min-width: 32rem)
  {
    padding: 1rem 3rem;
  }
}

.gdprMenu {
  list-style:none;

  li {
    display:inline-block;
    margin-right: 1rem;
  }
}

button {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}


</style>
