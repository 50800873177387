<template lang="pug">

  section(:class='$style.container')

    span(:class='[$style.trigger, {[$style.triggerActive]: dropdown}]')

        span(
            @click='dropdown = !dropdown'
        ) {{ locales[$route.meta.locale].services }}

            svg(viewBox='0 0 23 43')

                polyline(points='21.5 1.5 1.5 21.5 21.5 41.5')

    div(:class='[$style.dropdown, {[$style.dropdownActive]: dropdown}]')

        div(:class='$style.dropdownInner')

            div(v-for='(competence, index) in competences')

                span {{ index + 1 }}

                span {{ competence.value }}

    div(
        :class='$style.slide'
        @touchstart='touchstart',
        @touchend='touchend'
    )

        transition(
            @enter='slideIn'
            @leave='slideOut'
            :css='false'
        )

            p(':key'='current') {{ competences[current].value }}


        transition(
            mode='out-in'
            @enter='fadeIn'
            @leave='fadeOut'
            :css='false'
        )

            span(':key'='current') {{ current + 1 }}

    div(:class='$style.dots')

        span(
            v-for='(competence, index) in competences'
            @click='goTo(index)'
            :class='[$style.dot, {[$style.dotActive]: index === current}]'
        )

    svg(
        :class='[$style.arrow, $style.arrowPrev]'
        viewBox='0 0 23 43'
        @click='goTo(current - 1)'
    )

        polyline.cls-1(points='21.5 1.5 1.5 21.5 21.5 41.5')

    svg(
        :class='[$style.arrow, $style.arrowNext]'
        viewBox='0 0 23 43'
        @click='goTo(current + 1)'
    )

        polyline.cls-1(points='21.5 1.5 1.5 21.5 21.5 41.5')

</template>

<script>

import anime from 'animejs'

export default {
    name: 'competencesSlider',
    props: ['locales', 'competences'],
    data ()
    {
        return {
            current: 0,
            ltr: true,
            dropdown: false,
            x: 0,
            y: 0
        }
    },
    methods:
    {
        goTo (index)
        {
            this.current = this.indexHelper(index)
        },
        indexHelper (index)
        {
            if (index < 0)
            {
                this.ltr = false
                return this.competences.length - 1
            }
            if (index === this.competences.length)
            {
                this.ltr = true
                return 0
            }

            this.ltr = (this.current < index)
            return index
        },
        slideIn (el, done)
        {
            const dir = (this.ltr) ? '' : '-'

            anime({
                targets: el,
                translateX: [`${dir}100%`, '0%'],
                translateY: '-50%',
                easing: 'easeInQuad',
                duration: 400,
                complete: done
            })
        },
        slideOut (el, done)
        {
            const dir = (this.ltr) ? '-' : ''

            anime({
                targets: el,
                translateX: ['0%', `${dir}100%`],
                translateY: '-50%',
                easing: 'easeOutQuad',
                duration: 400,
                complete: done
            })
        },
        fadeIn (el, done)
        {
            anime({
                targets: el,
                opacity: [0, 1],
                easing: 'linear',
                duration: 300,
                complete: done
            })
        },
        fadeOut (el, done)
        {
            anime({
                targets: el,
                opacity: [1, 0],
                easing: 'linear',
                duration: 300,
                complete: done
            })
        },
        touchstart (event)
        {
            this.x = event.touches[0].clientX
            this.y = event.touches[0].clientY
        },
        touchend (event)
        {
            const x = this.x - event.changedTouches[0].clientX
            const y = this.y - event.changedTouches[0].clientY
            const xAbs = Math.abs(x)
            const yAbs = Math.abs(y)

            if (xAbs > yAbs * 2)
            {
                (x > 0) ? this.goTo(this.current + 1) : this.goTo(this.current - 1)
            }
        }
    }
}
</script>

<style module lang='scss'>

.container {
    position: relative;
    overflow: hidden;
    height: 60vh;
    @media screen and (min-width: 64rem) {
        min-height: 36rem;
    }

    svg {
        fill:none;
        stroke:#000;
        stroke-linecap:round;
        stroke-linejoin:round;
        stroke-width:3px;
        transition: stroke 0.3s;
        cursor: pointer;
    }
}

.trigger {
    @include bgGrey3;
    display: none;
    padding: 1rem;
    text-align: center;
    font-weight: bold;
    position: relative;
    z-index: 4;
    @media screen and (min-width: 64rem) {
        display: block;
    }

    span {
        position: relative;
        transition: all 0.3s;
        cursor: pointer;


        svg {
            position: absolute;
            left: -1.8rem;
            bottom: -0.1rem;
            width: 0.7rem;
            height: auto;
            transform: rotate(-90deg);
            transition: all 0.3s;
        }

        &:hover {
            @include fgPurple;

            svg {
                stroke:$purple;
            }
        }
    }
}

.triggerActive {

    span {

        svg {
            transform: rotate(90deg);
        }
    }
}

.dropdown {
    @include bgGrey3;
    box-sizing: border-box;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 3;
    transform: translateY(-100%);
    transition: transform 0.6s;
    display: none;
    @media screen and (min-width: 64rem) {
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

.dropdownActive {
    transform: translateY(0%);
}

.dropdownInner {
    @include semifluid;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    align-items: center;
    grid-gap: 2rem;

    span
    {
        display: block;
        text-align: center;
    }

    span:nth-of-type(1)
    {
        @include fgPurple;
        font-weight: bold;
        font-size: 4rem;
        font-family: "leitura-news",serif;
        font-style: normal;
        font-weight: 400;
    }

    span:nth-of-type(2)
    {
        @include fMiddle;
    }
}

.slide {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    z-index: 1;

    p {
        @include fTitle2;
        position: absolute;
        top: 50%;
        width: 100%;
        text-align: center;
        box-sizing: border-box;
        font-weight: bold;
        z-index: 1;
        transform: translate(0%, -50%);
    }

    span {
        @include fgGrey3;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-family: "leitura-news",serif;
        font-style: normal;
        font-weight: 400;
        font-size: 35vh;
    }
}

.arrow {
    display: none;
    width: 1.4rem;
    height: auto;
    position: absolute;
    z-index: 2;
    @media screen and (min-width: 32rem)
    {
        display: block;
    }

    &:hover {
        stroke: $purple;
    }
}

.arrowPrev {
    top:50%;
    left: 1rem;
    transform: translateY(-50%);
}

.arrowNext {
    top:50%;
    right: 1rem;
    transform: translateY(-50%) rotate(180deg);
}

.dots {
    position: absolute;
    bottom: 6vw;
    display: flex;
    width: 100%;
    justify-content: center;
    z-index: 2;
}

.dot {
    @include bgGrey3;
    display: block;
    height: 9px;
    width: 9px;
    border-radius: 100%;
    margin: 0 5px;
    cursor: pointer;
    @media screen and (min-width: 32rem)
    {
        margin: 0 0.3rem;
    }

    &:hover {
        @include bgPurple;
    }
}

.dotActive {
    background-color: #000;
    transition: color 0.3s;
}

</style>
