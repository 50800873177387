import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

export default new Router({
    mode: 'history',
    routes:
    [
        {
            path: '/',
            meta: {
                locale: 'german'
            }
        },
        {
            path: '/en',
            meta: {
                locale: 'english'
            }
        },
        {
            path: '/it',
            meta: {
                locale: 'italian'
            }
        }
    ]
})
