<template lang="pug">

    section(v-if="showImpressum" :class='[$style.container, "impressum"]')

        div(:class='$style.semifluid')

            div(
              :class='$style.textContainer'
              v-html="impressum"
            )

</template>

<script>

export default {
    name: 'impressum',
    props: ['impressum', 'showImpressum']
}

</script>

<style module lang='scss'>

.container {
    @include padV;
    background: $grey;
}

.semifluid {
    @include semifluid;
}

.textContainer {
    margin-bottom: 1rem;
    width: 100%;
    @media screen and (min-width: 64rem) {
        width: calc(100% - 18rem);
    }

  h1, h2, h3 {
    @include fBold;
  }

  h1 {
    @include fTitle2;
    margin-bottom: .5rem;
  }

  h2 {
    @include fUpper;
    font-size: 0.9rem;
    margin-bottom: .5rem;
    @media screen and (min-width: 25rem) {
      font-size: 1.3rem;
      margin-bottom: .7rem;
    }
  }
  h3 {
    margin-bottom:.7rem;
  }

  p, ul {
    font-size: 0.9rem;
    margin-bottom: .5rem;
      @media screen and (min-width: 25rem) {
        font-size: 1rem;
        margin-bottom: 1.5rem;
      }
  }

  ul {
    list-style-position: inside;
    list-style-type: none;
    font-size: 0.9rem;
    margin-left: 0;

    li {
      list-style: none;
      margin-left: 0;
      padding-left: 1em;
      margin-bottom: .3rem;

      &:before {
        display: inline-block;
        content: "–";
        width: 1em;
        margin-left: -1em;
      }
    }
  }
  strong {
    @include fBold;
  }

}


</style>
