<template lang="pug">

    section(:class='$style.container')

        div(:class='$style.semifluid')

            header

                h2(:class='$style.headline') {{ title }}

            p(:class='$style.lead') «{{ lead }}»

            div(:class='$style.bubbles')

                div(
                    :class='$style.bubble'
                    v-for='bubble in bubbles'
                )

                    div(:class='$style.bubbleInner')

                        p {{ bubble.value }}

</template>

<script>

export default {
    name: 'cryptos',
    props: ['title', 'lead', 'bubbles']
}
</script>

<style module lang='scss'>

.container {

    @include padV;
}

.semifluid {
    @include semifluid;
}

.headline {
    @include fTitle2;
    @include fCenter;
    margin-bottom: 0.8rem;
}

.lead {
    @include fBig;
    @include fCenter;
    margin-bottom: 3rem;
}

.bubbles {
    display: grid;
    max-width: 60rem;
    margin: 0 auto;
    grid-gap: 2rem;
    grid-template-columns: repeat(1, 1fr);
    @media screen and (min-width: 32rem) {
        grid-template-columns: repeat(2, 1fr);

    }
    @media screen and (min-width: 58rem) {
        grid-template-columns: repeat(3, 1fr);
    }
}

.bubble {
    position: relative;
    width: 100%;
    padding-top: 100%;
    position: relative;
}

.bubbleInner {
    @include bgGrey2;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 100%;

    p {
        @include fMiddle;
        @include fCenter;
        position: absolute;
        top: 50%;
        width: 100%;
        margin: 0;
        padding: 0 20%;
        box-sizing: border-box;
        transform: translateY(-50%);
    }
}

</style>
