import Vue from 'vue'
import App from './App.vue'
import router from './router'
import VueLazyLoader from 'vue-lazy-loader'
import Scrollspy from '@/directives/scrollspy.js'
import smoothscroll from 'smoothscroll-polyfill'

smoothscroll.polyfill()

Vue.use(VueLazyLoader, {
    root: null,
    rootMargin: '0px 0px 0px 0px',
    threshold: 0
})

Vue.use(Scrollspy)

Vue.config.productionTip = false

new Vue({
    router,
    render: h => h(App)
}).$mount('#app')
